export default (self) => [
  {
    value: "name",
    text: "Project Name",
    visible: true,
    width: 100,
  },
  {
    value: "exid",
    text: "Project ID",
    visible: true,
    width: 100,
  },
  {
    value: "startedAt",
    text: "Project Start Date",
    width: 300,
    visible: true,
    toStringFn: (item, key) => self.formatDate(item[key]),
  },
]
