export default (self) => [
  {
    value: "name",
    text: "Name",
    visible: true,
    width: 100,
  },
  {
    value: "cameraExid",
    text: "Camera ID",
    visible: true,
    width: 150,
  },
  {
    value: "ipAddress",
    text: "IP Address",
    visible: true,
    width: 100,
  },
  {
    value: "browser",
    text: "Browser",
    visible: true,
    width: 200,
  },
  {
    value: "createdAt",
    text: "Created At",
    visible: true,
    width: 150,
    toStringFn: (item, key) => self.formatDate(item[key]),
  },
]
