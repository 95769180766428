<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template #activator="{ on: onDialog, attrs: attrsDialog }">
        <v-tooltip left :disabled="!hasCameras">
          <template #activator="{ on: onTooltip, attrs: attrsTooltip }">
            <div v-bind="attrsTooltip" v-on="onTooltip">
              <v-btn
                color="error"
                small
                :disabled="hasCameras"
                v-bind="attrsDialog"
                v-on="onDialog"
                >delete</v-btn
              >
            </div>
          </template>
          Cannot delete a camera owner
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title>Are you absolutely sure?</v-card-title>

        <ValidationObserver ref="form" v-slot="{ invalid, passes }">
          <v-card-text>
            <ValidationProvider
              v-slot="{ errors }"
              name="Email"
              :rules="{ required: true, email: true, equal_to: `${userEmail}` }"
            >
              <v-text-field
                v-model="emailVerify"
                :error-messages="errors"
                hint="Enter the email of the user you want to delete"
                persistent-hint
                outlined
                dense
              >
                <template #label>
                  <div class="caption label-color">Email</div>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              :disabled="invalid"
              :loading="loading"
              @click="passes(deleteUser)"
              >Yes, delete this user</v-btn
            >
            <v-btn color="primary" text @click="close">Cancel</v-btn>
          </v-card-actions>
        </ValidationObserver>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { AdminApi } from "@evercam/shared/api/adminApi"

export default {
  name: "UsersDeleteDialog",
  props: {
    userEmail: {
      type: String,
      default: null,
    },
    hasCameras: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emailVerify: null,
      dialog: false,
      loading: false,
    }
  },
  methods: {
    async deleteUser() {
      try {
        this.loading = true
        await AdminApi.users.deleteUser(this.emailVerify, {
          verifEmail: this.emailVerify,
        })
        this.$emit("delete", this.emailVerify)
        this.$notifications.success("User has been deleted successfully")
      } catch (error) {
        this.$notifications.error({ text: "Cannot delete the user", error })
      } finally {
        this.loading = false
        this.close()
      }
    },
    close() {
      this.$refs.form?.reset()
      this.dialog = false
    },
  },
}
</script>
