export default [
  {
    value: "name",
    text: "Camera Name",
    visible: true,
    width: 100,
  },
  {
    value: "exid",
    text: "Camera ID",
    visible: true,
    width: 100,
  },
  {
    value: "ownerFullname",
    text: "Owner",
    width: 100,
    visible: true,
  },
  {
    value: "ownerEmail",
    text: "Email",
    width: 300,
    visible: true,
  },
]
