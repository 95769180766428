<template>
  <v-dialog :value="value" max-width="1100" @input="(v) => $emit('input', v)">
    <v-card>
      <v-card-title v-if="showHeader">
        <ERow>
          <ECol cols="12" lg="8" md="8" sm="6" class="pt-0"
            ><span class="subtitle-2 black--text text--lighten-4">{{
              sim
            }}</span>
            <span v-if="getSimProvider" class="mx-2">|</span>
            <span
              v-if="getSimProvider"
              class="subtitle-2 black--text text--lighten-4"
            >
              Sim Provider:
              <span class="ml-1 caption">{{ getSimProvider }}</span>
            </span>
            <span v-if="getBillPayer" class="mx-2">|</span>
            <span
              v-if="getBillPayer"
              class="subtitle-2 black--text text--lighten-4"
            >
              Bill Payer:
              <span class="ml-1 caption">{{ getBillPayer }}</span>
            </span>
          </ECol>
          <v-btn
            class="close-icon"
            color="primary"
            icon
            dark
            large
            @click="$emit('input', false)"
          >
            <v-icon>fa-times</v-icon>
          </v-btn>
        </ERow>
      </v-card-title>
      <v-card-text :class="{ 'pt-4': !showHeader }" class="px-4">
        <SmsHistory :sim-number="sim" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SmsHistory from "@/components/cameraTabs/SmsHistory"

export default {
  components: {
    SmsHistory,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    sim: {
      type: String,
      default: "",
    },
    selectedSim: {
      type: Object,
      default: () => ({}),
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getSimProvider() {
      return this.selectedSim?.simProvider
    },
    getBillPayer() {
      return this.selectedSim?.billPayer
    },
  },
}
</script>
