<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title>Password reset link</v-card-title>

      <v-card-text>
        {{ passwordResetLink }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <CopyToClipboardBtn
          :text="passwordResetLink"
          buttonText="Copy Link"
          textOnlyButton
          color="primary"
        />
        <v-btn color="primary" text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CopyToClipboardBtn from "@evercam/shared/components/CopyToClipboardBtn"

export default {
  name: "UsersPasswordResetDialog",
  components: { CopyToClipboardBtn },
  props: {
    resetLink: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  computed: {
    passwordResetLink() {
      return this.resetLink
    },
  },
  watch: {
    "$attrs.value": {
      immediate: true,
      handler(value) {
        this.dialog = value
      },
    },
    dialog(val) {
      this.$emit("input", val)
    },
  },
  methods: {
    close() {
      this.dialog = false
    },
  },
}
</script>
