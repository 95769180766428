<template>
  <div class="user-details">
    <v-list-item v-for="(item, index) in details" :key="index" class="px-0">
      <v-list-item-content class="py-0 pt-1">
        <v-list-item-title class="caption font-weight-bold">{{
          item.label
        }}</v-list-item-title>
        <v-list-item-subtitle v-if="item.isLink">
          <v-tooltip v-if="userDetails.zohoId" right>
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                color="primary"
                v-bind="attrs"
                target="_blank"
                :href="item.link"
                v-on="on"
              >
                {{ item.icon }}
              </v-btn>
            </template>
            <span>{{ item.tooltip }}</span>
          </v-tooltip>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>{{ item.value }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: {
    userDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    details() {
      return [
        {
          label: "Email",
          value: this.userDetails.email,
        },
        {
          label: "# Projects",
          value: this.userDetails.projectsCount,
        },
        {
          label: "# Cameras",
          value: this.userDetails.camerasCount,
        },
        {
          label: "Last seen",
          value: this.userDetails.lastEventCreatedAt,
        },
        {
          label: "Last seen browser",
          value:
            this.userDetails.lastEventBrowser && this.userDetails.lastEventOs
              ? `${this.userDetails.lastEventBrowser} on ${this.userDetails.lastEventOs}`
              : "-",
        },
        {
          label: "Last seen IP",
          value: this.userDetails.lastEventIpAddress,
        },
        {
          label: "Zoho Link",
          tooltip: "Open Zoho Account",
          link: `${this.$config.public.zohoCrmUrl}/tab/Contacts/${this.userDetails.zohoId}`,
          isLink: !!this.userDetails.zohoId,
          value: " - ",
          icon: "Z",
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.user-details {
  display: flex;
  flex-wrap: wrap;
  .v-list-item {
    margin-bottom: 5px;
    min-height: 0px !important;
    flex: 1 1 30%;
  }
}
</style>
