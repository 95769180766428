<template>
  <v-dialog v-model="dialog" max-width="300px" persistent @keydown.esc="close">
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="forFormComponent && !editSummaryRouterSim"
        color="primary"
        v-bind="attrs"
        class="mt-n2"
        v-on="on"
      >
        Create New SIM
      </v-btn>
      <v-btn
        v-else-if="!editSummaryRouterSim"
        color="primary"
        v-bind="attrs"
        class="mr-1"
        small
        @click="openDialog"
      >
        New SIM
      </v-btn>
      <v-btn
        v-if="!forFormComponent && !editSummaryRouterSim"
        color="primary"
        v-bind="attrs"
        class="mx-1"
        small
        :disabled="!isMultipleSims"
        v-on="on"
      >
        Mass Update
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span>{{ formTitle }}</span>
      </v-card-title>
      <v-divider />
      <ValidationObserver v-slot="{ invalid, passes }" ref="simForm" disabled>
        <v-card-text>
          <v-form style="width: 100%">
            <ERow no-gutters>
              <ECol cols="12">
                <ValidationProvider
                  v-if="!isMultipleSims"
                  v-slot="{ errors }"
                  name="Number"
                  rules="required"
                >
                  <v-text-field
                    v-model="editedItem.number"
                    dense
                    outlined
                    :error-messages="errors"
                    label="Number"
                    placeholder="i.e +353xxxxxx"
                    @keydown.space.prevent
                  />
                </ValidationProvider>
              </ECol>
              <ECol v-if="isMultipleSims" cols="4">
                <v-checkbox
                  v-model="canUpdateSimProvider"
                  label="Update ?"
                  dense
                ></v-checkbox>
              </ECol>
              <ECol :cols="columnSize">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="SIM provider"
                  :rules="
                    canUpdateSimProvider ? 'required|provider_exists' : ''
                  "
                >
                  <v-combobox
                    ref="simProvider"
                    v-model="editedItem.simProvider"
                    :items="simProviders"
                    label="SIM provider"
                    placeholder="Select an option or type one"
                    persistent-hint
                    dense
                    outlined
                    :error-messages="errors"
                    :disabled="!canUpdateSimProvider"
                  />
                </ValidationProvider>
              </ECol>
              <ECol v-if="isMultipleSims" cols="4">
                <v-checkbox
                  v-model="canUpdateBillPayer"
                  label="Update ?"
                  dense
                ></v-checkbox>
              </ECol>
              <ECol :cols="columnSize">
                <v-combobox
                  ref="billPayer"
                  v-model="editedItem.billPayer"
                  :items="billPayers"
                  placeholder="Select an option or type one"
                  dense
                  outlined
                  label="Bill Payer"
                  :disabled="!canUpdateBillPayer"
                />
              </ECol>
              <ECol v-if="!isMultipleSims" cols="12">
                <v-text-field
                  v-model="editedItem.pin1"
                  dense
                  outlined
                  label="PIN 1"
                  placeholder="i.e 0000"
                  @keydown.space.prevent
                />
              </ECol>
              <ECol v-if="!isMultipleSims" cols="12">
                <v-text-field
                  v-model="editedItem.pin2"
                  dense
                  outlined
                  label="PIN 2"
                  placeholder="i.e 0000"
                  @keydown.space.prevent
                />
              </ECol>
              <ECol v-if="!isMultipleSims" cols="12">
                <v-text-field
                  v-model="editedItem.puk"
                  dense
                  outlined
                  label="PUK"
                  placeholder="i.e 0000"
                  @keydown.space.prevent
                />
              </ECol>
              <ECol v-if="!isMultipleSims" cols="12">
                <v-text-field
                  v-model="editedItem.iccid"
                  dense
                  outlined
                  label="ICCID"
                  placeholder="i.e 345435"
                  @keydown.space.prevent
                />
              </ECol>
              <ECol v-if="!isMultipleSims" cols="12">
                <v-text-field
                  v-model="editedItem.description"
                  dense
                  outlined
                  label="Description"
                  placeholder="i.e Description"
                />
              </ECol>
            </ERow>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"> Cancel </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="
              invalid || (!canUpdateSimProvider && !canUpdateBillPayer)
            "
            @click="passes(save)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
  </v-dialog>
</template>

<script>
import { extend } from "vee-validate"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { mapStores } from "pinia"
import { useReportStore } from "@/stores/report"

export default {
  props: {
    forFormComponent: {
      type: Boolean,
      default: false,
    },
    editedSim: {
      type: Object,
      default: () => ({}),
    },
    selectedSims: {
      type: Array,
      default: () => [],
    },
    editSummaryRouterSim: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      editedIndex: -1,
      editedItem: {
        number: "",
        simProvider: "",
        billPayer: "",
        pin1: "",
        pin2: "",
        puk: "",
        iccid: "",
        description: "",
      },
      defaultItem: {
        number: "",
        simProvider: "",
        billPayer: "",
        pin1: "",
        pin2: "",
        puk: "",
        iccid: "",
        description: "",
      },
      simProviders: [],
      billPayers: [],
      canUpdateSimProvider: true,
      canUpdateBillPayer: true,
    }
  },
  computed: {
    ...mapStores(useReportStore),
    formTitle() {
      if (this.isMultipleSims) {
        return "Update SIMs"
      }
      if (this.editedIndex === -1 && !this.editSummaryRouterSim) {
        return "Add SIM"
      }

      return "Edit SIM"
    },
    isMultipleSims() {
      return this.selectedSims.length > 1
    },
    columnSize() {
      return this.selectedSims.length > 1 ? "8" : "12"
    },
  },
  watch: {
    editedSim: {
      deep: true,
      handler() {
        if (this.editedSim.id) {
          this.editItem(this.editedSim)
          this.$emit("clear-selected", {})
        }
      },
    },
    dialog(val) {
      if (val) {
        this.getSimProviders()
        this.getBillPayers()
      }
    },
  },
  mounted() {
    extend("provider_exists", {
      message: "Provider exists, check typos or spaces !",
      validate: (input) => {
        const provider = this.simProviders.find((el) => el == input)
        if (provider) {
          return true
        } else {
          input = input.toLowerCase().replaceAll(/\s/g, "")
          const sanitizedProvider = this.simProviders
            .map((el) => {
              return el.toLowerCase().replaceAll(/\s/g, "")
            })
            .find((el) => el == input)

          return !sanitizedProvider
        }
      },
    })
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.reportStore.items.indexOf(item)
      this.editedItem = item
      this.dialog = true
    },
    close() {
      this.canUpdateSimProvider = true
      this.canUpdateBillPayer = true
      this.dialog = false
      if (!this.editSummaryRouterSim) {
        this.$refs.simForm.reset()
      }
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
        this.$emit("clear-summary-sim")
      })
    },
    openDialog() {
      this.dialog = true
      this.$emit("clear-selected", {})
    },
    save() {
      this.reportStore.loading = true
      this.$refs.simProvider.blur()
      this.$refs.billPayer.blur()
      this.$nextTick(async () => {
        if (this.isMultipleSims) {
          await this.updateMultiSims()
        } else if (this.editedIndex > -1 || this.editSummaryRouterSim) {
          await this.updateSingleSim()
        } else {
          await this.createSim()
        }
        this.addSimProvider()
        this.addBillPayer()
        this.$emit("reload-sims", this.editedItem.number)
        this.close()
        this.reportStore.searchFilters = {}
        this.reportStore.loading = false
      })
    },
    async updateMultiSims() {
      try {
        for (const sim of this.selectedSims) {
          this.editedItem = {
            billPayer: this.canUpdateBillPayer
              ? this.editedItem.billPayer
              : sim.billPayer,
            simProvider: this.canUpdateSimProvider
              ? this.editedItem.simProvider
              : sim.simProvider,
          }
          await AdminApi.sims.updateSim(sim.id, this.editedItem)
        }
        this.$notifications.success("Selected SIMs have been updated.")
        this.$emit("clear-selected", {})
      } catch (error) {
        this.$notifications.error({
          text: "Could not update the selected SIM(s)!",
          error,
        })
      }
    },
    async updateSingleSim() {
      try {
        await AdminApi.sims.updateSim(this.editedItem.id, this.editedItem)
        this.$notifications.success("SIM has been updated.")
        if (this.editSummaryRouterSim) {
          this.$emit("refetch-router-details")
        }
      } catch (error) {
        this.$notifications.error({
          text: "Could not update the selected SIM!",
          error,
        })
      }
    },
    async createSim() {
      try {
        await AdminApi.sims.createSim(this.editedItem)
        this.$notifications.success("SIM has been added.")
      } catch (error) {
        this.$notifications.error({
          text: "Could not add SIM!",
          error,
        })
      }
    },
    addSimProvider() {
      if (!this.simProviders.includes(this.editedItem.simProvider)) {
        this.$emit("update-sim-providers", true)
      }
    },
    addBillPayer() {
      if (!this.billPayers.includes(this.editedItem.billPayer)) {
        this.$emit("update-bill-payers", true)
      }
    },
    async getSimProviders() {
      try {
        this.simProviders = await AdminApi.sims.getSimProviders()
      } catch (error) {
        this.$notifications.error({
          text: "Could not load sim providers!",
          error,
        })
      }
    },
    async getBillPayers() {
      try {
        this.billPayers = await AdminApi.sims.getBillPayers()
      } catch (error) {
        this.$notifications.error({
          text: "Could not load bill payers!",
          error,
        })
      }
    },
  },
}
</script>
